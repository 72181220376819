export default {
  0: {
    class: "status--draft",
    text: "Начал заполнение",
    id: 0,
  },
  1: {
    class: "status--revision",
    text: "На рассмотрении",
    id: 1,
  },
  27: {
    class: "status--rejected",
    text: "Не отвечает",
    id: 27,
  },
  28: {
    class: "status--revision",
    text: "Неверные контактные данные",
    id: 28,
  },
  35: {
    class: "status--checking",
    text: "На доработке",
    id: 35,
  },
  40: {
    class: "status--rejected",
    text: "Отказано",
    id: 40,
  },
  45: {
    class: "status--draft",
    text: "Отозвал заявку",
    id: 45,
  },
  50: {
    class: "status--accept",
    text: "Заявка одобрена лигой",
    id: 50,
  },
  80: {
    class: "status--winner",
    text: "Участник",
    id: 80,
  },
  85: {
    class: "status--draft",
    text: "Резерв",
    id: 85,
  },
  94: {
    class: "status--winner",
    text: "Спец. приз",
    id: 94,
  },
  95: {
    class: "status--winner",
    text: "Лауреат",
    id: 95,
  },
  100: {
    class: "status--winner",
    text: "Победитель конкурса",
    id: 100,
  },
  // member: {
  //   class: "status--member",
  //   text: "Участник",
  // },
};
