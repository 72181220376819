<template>
  <div class="title" :class="className">
    <div class="title__text">
      <router-link :to="{ name: 'Events' }">
        <Icon class="icon-Arrow-left" />
      </router-link>
      {{ label }}
    </div>
    <Status
      v-if="applicationInfo"
      :statusValue="+currentStatus"
      :text="statusText"
    />
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
import { mapState } from "vuex";
import Status from "@/components/Blocks/Status.vue";
export default {
  name: "Title",
  components: { Status, Icon },
  props: {
    icon: Boolean,
    label: String,
    border: Boolean,
    applicationInfo: Object,
  },
  computed: {
    statuses() {
      let sortedArr =
        JSON.parse(
          JSON.stringify(
            this.storage?.systemInfo?.list_statuses[
              `${this.applicationInfo?.info_project?.key}`
            ] ?? []
          )
        ) ?? [];
      return sortedArr;
    },
    currentStatus() {
      return Object.keys(this.statuses).find((item) => {
        return +item === this.applicationInfo?.status?.key;
      });
    },
    className() {
      return {
        "title--icon": this.icon,
        "title--border": this.border,
      };
    },
    statusText() {
      let status = undefined;
      if (Object.keys(this.applicationInfo)?.length > 0) {
        if (this.applicationInfo?.info_project?.id === 5) {
          if (this.currentStatus === "100") {
            status = "Приглашён на форум";
          } else if (this.currentStatus === "50") {
            status = "Заявка допущена";
          }
        }
      } else {
        status = "Начал заполнение";
      }

      return status;
    },
    ...mapState(["storage"]),
  },
};
</script>

<style lang="scss">
.title {
  display: flex;
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 500;
  margin-bottom: 20px;
  align-items: flex-end;
  flex-wrap: wrap;
  &__text {
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
  }
  .status {
    font-size: 2.2rem;
  }
  @include adaptive(phone) {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 15px;
    .status {
      font-size: 1.6rem;
    }
  }

  & a {
    display: none;
    & i {
      position: relative;
      font-size: 2.4rem;
      font-weight: 500;
      color: var(--text--color);
      @include adaptive(phone) {
        font-size: 1.4rem;
      }
    }
  }
  &--icon {
    gap: 10px;
    @include adaptive(phone) {
      gap: 5px;
    }
    & a {
      display: flex;
      align-items: center;
    }
  }
  &--border {
    border-bottom: 1px solid var(--background--btn--profile--hover);
    padding-bottom: 20px;
    @include adaptive(tablet-big) {
      padding-bottom: 15px;
    }
  }
}
</style>
