<template>
  <div class="comment">
    <div class="comment__title">
      <span><Icon class="icon-Person" /></span>
      <p>{{ status }} Комментарий ответственного:</p>
    </div>
    <div class="comment__text">
      {{ comment }}
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Comment",
  components: { Icon },
  props: ["comment", "status"],
};
</script>

<style lang="scss">
.comment {
  color: #eb6e40;
  background: #ffe4da;
  border-radius: 10px;
  padding: 24px 40px;
  &__title {
    display: flex;
    gap: 7px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  @include adaptive(tablet-big) {
    padding: 16px 20px;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}
</style>
