<template>
  <div
    class="status"
    :class="[currentStatus?.class, { 'status--empty-tooltip': emptyTooltip }]"
    :data-tooltip="
      hasComments
        ? 'Внутри заявки оставлен комментарий'
        : 'Первичная информация проверена. Портфолио и достижения будут рассматриваться ответственным в образовательной организации или экспертом'
    "
  >
    <slot>
      <span>{{ text ?? currentStatus?.text }}</span>
      <Icon type="comment" v-if="hasComments" />
      <Icon type="comment" v-if="isStatusAdmitted" />
    </slot>
  </div>
</template>

<script>
import Status from "@/enums/status";
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Status",
  components: { Icon },
  data() {
    return {
      Status,
    };
  },
  props: {
    statusValue: Number,
    label: String,
    accept: Boolean,
    revision: Boolean,
    checking: Boolean,
    winner: Boolean,
    member: Boolean,
    rejected: Boolean,
    draft: Boolean,
    hasComments: Boolean,
    isStatusAdmitted: Boolean,
    iconType: String,
    text: String,
  },
  computed: {
    emptyTooltip() {
      return !this.hasComments && !this.isStatusAdmitted;
    },
    currentStatus() {
      return this.Status[this.statusValue];
    },
    className() {
      return {
        "status--accept": this.accept,
        "status--revision": this.revision,
        "status--checking": this.checking,
        "status--winner": this.winner,
        "status--member": this.member,
        "status--rejected": this.rejected,
        "status--draft": this.draft,
      };
    },
  },
};
</script>

<style lang="scss"></style>
